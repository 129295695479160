<template>
  <a-modal :visible="true" title="编辑部门" :maskClosable="false" :width="930" @ok="submit" @cancel="cancel"
    :confirm-loading="confirmLoading" :closable="false">
    <a-form-model ref="ruleForm" :model="form" :rules="rules">
      <a-form-model-item label="部门名称" prop="organizationName">
        <a-input v-model.trim="form.organizationName" style="width: 400px;" placeholder="请输入部门名称，不超过20个字"
          :maxLength="20" />
      </a-form-model-item>
      <a-form-model-item label="部门负责人">
        <div style="display: flex;justify-content: space-between;">
          <a-input v-model.trim="searchForm.account" placeholder="请输入账号" class="inputClass" />
          <a-input v-model.trim="searchForm.userName" placeholder="请输入姓名" style="margin-left: 20px;" class="inputClass" />
          <a-input v-model.trim="searchForm.phoneNumber" placeholder="请输入手机号" style="margin-left: 20px;"
            class="inputClass" />
          <a-button type="primary" @click="search" style="margin-left: 20px;" :loading="searchBtnLoading"
            :disabled="searchDisabled">
            <a-icon type="search" /> 查询
          </a-button>
        </div>

      </a-form-model-item>
    </a-form-model>
    <a-table :row-selection="{
      selectedRowKeys: table.selectedRowKeys,
      onChange: selectedStaff,
      type: 'radio',
    }" :columns="table.columns" :data-source="table.dataInfo" :pagination="false"
      :rowKey="(record, index) => record.userId" class="my-20" bordered style="margin-top: 20px" />
    <a-form-model-item style="margin-top: 20px;" label="" prop="account">
      <div style="display: flex;align-items: center;">
        <span><span style="color: #f5222d;
    font-size: 14px;">*</span> 账号：</span> <a-input style="width: 400px;" disabled v-model.trim="form.account"
          placeholder="请选择" />
      </div>

    </a-form-model-item>
    <a-form-model-item label="" prop="userName">
      <div style="display: flex;align-items: center;">
        <span><span style="color: #f5222d;
    font-size: 14px;">*</span>姓名：</span> <a-input style="width: 400px;" disabled v-model.trim="form.userName"
          placeholder="请选择" />
      </div>

    </a-form-model-item>
    <a-form-model-item label="" prop="phoneNumber">
      <div style="display: flex;align-items: center;">
        <span><span style="color: #f5222d;
    font-size: 14px;">*</span>手机号：</span> <a-input style="width: 400px;" disabled v-model.trim="form.phoneNumber"
          placeholder="请选择" />
      </div>

    </a-form-model-item>
    <a-form-model-item label="" prop="feishuid">
      <div style="display: flex;align-items: center;">
        <span>飞书open_id：</span> <a-input style="width: 400px;" v-model.trim="form.openId" placeholder=""
          class="inputClass" />
      </div>
    </a-form-model-item>
  </a-modal>
</template>

<script>
import { addStaffSearchForm, addStaffTableConfig } from '../../../help';
import * as api from '@/api/demandPool';
import { SUCCESS_CODE } from '@/config';
export default {
  name: 'EditDepartment',
  props: {
    // 是否显示
    showEditDepartment: {
      type: Boolean,
      default: false,
    },
    // 编辑信息
    editInfo: {
      type: Object,
      default: () => { },
    },
  },
  computed: {
    searchDisabled() {
      return this.searchForm.account || this.searchForm.userName || this.searchForm.phoneNumber ? false : true;
    },
  },
  watch: {
    editInfo: {
      handler(newVal) {
        if (newVal) this.form = { ...newVal };
      },
      immediate: true,
    },
  },
  data() {
    return {
      table: { ...addStaffTableConfig },
      confirmLoading: false, // 提交按钮loading
      form: {
        organizationName: '', // 编辑当前部门名称
        organizationId: '', // 编辑当前部门id
        principalId: '',// 组织负责人id
        phoneNumber: '',
        openId: '',
        userName: '',
        account: ''
      },
      searchForm: {
        ...addStaffSearchForm,
      },
      searchBtnLoading: false,
      rules: { organizationName: [{ required: true, message: '请输入组织名称', trigger: 'blur' }] },
    };
  },
  methods: {
    // 提交
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.editDepartment();
        }
      });
    },
    // 取消
    cancel() {
      this.$emit('update:show-edit-department', false);
    },
    /**网络请求 */
    // 编辑部门
    async editDepartment() {
      try {
        this.confirmLoading = true;
        const result = await api.editEepartmentForDemandPool(this.form);
        this.confirmLoading = false;
        if (result.code === SUCCESS_CODE) {
          this.$message.success(result.msg);
          this.$emit('update:show-edit-department', false);
          this.$emit('refreshDepartmentList');
        } else {
          this.$message.error(result.msg);
          this.confirmLoading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 选中人员
    selectedStaff(items,selectedRows) {
      this.table.selectedRowKeys = items;
      this.form.userId = items[0];
      this.form.principalId = items[0];
      this.form.userName = selectedRows[0].userName
      this.form.account = selectedRows[0].account
      this.form.phoneNumber = selectedRows[0].phoneNumber
      this.form.openId = selectedRows[0].openId
    },
    // 查询
    search() {
      this.searchStaffInfo();
    },
    /**网络请求 */
    async searchStaffInfo() {
      try {
        this.searchBtnLoading = true;
        const result = await api.addStaffSearchStaffInfoForDemandPool(this.searchForm);
        this.searchBtnLoading = false;
        if (result.code === SUCCESS_CODE) {
          this.table.dataInfo = result.data;
        }
      } catch (error) {
        this.searchBtnLoading = false;
        console.log(error);
      }
    },
  },
  created() { },
};
</script>

<style lang="scss" scoped></style>
