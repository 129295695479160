import moment from 'moment';
/** 人员管理 */
// 员工列表配置
export const staffTableConfig = {
  dataInfo: [],
  columns: [
    {
      title: '账号',
      dataIndex: 'account',
      key: 'account',
      width: 150,
    },
    {
      title: '姓名',
      dataIndex: 'userName',
      key: 'userName',
      width: 100,
      scopedSlots: { customRender: 'userName' },
    },
    {
      title: '手机号',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: 150,
    },
    {
      title: '飞书open_id',
      dataIndex: 'openId',
      key: 'openId',
      width: 150,
    },
    {
      title: '所属部门',
      dataIndex: 'organization',
      key: 'organization',
      width: 200,
      scopedSlots: { customRender: 'organization' },
    },
    {
      title: '角色',
      dataIndex: 'role',
      key: 'role',
      width: 150,
      scopedSlots: { customRender: 'role' },
      // customRender(text) {
      //   return text.split('，');
      // },
    },
    {
      title: '创建人',
      dataIndex: 'createUserName',
      key: 'createUserName',
      width: 100,
    },
    {
      title: '创建时间',
      dataIndex: 'gmtCreate',
      key: 'gmtCreate',
      width: 180,
    },
    {
      title: '最后编辑人',
      dataIndex: 'modifiedUserName',
      key: 'modifiedUserName',
      width: 120,
      customRender(text) {
        return text || '-';
      },
    },
    {
      title: '最后编辑时间',
      dataIndex: 'gmtModified',
      key: 'gmtModified',
      width: 180,
    },
    {
      title: '操作',
      key: 'action',
      width: 150,
      scopedSlots: { customRender: 'action' },
    },
  ],
  //分页数据
  pagination: {
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0,
    showQuickJumper: true,
    showTotal: (total) => {
      return `共 ${total} 条`;
    },
  },
};
// 员工列表搜索表单
export const staffSearchForm = {
  account: '', // 账号
  userName: '', // 用户昵称
  phoneNumber: '', // 用户手机号
  roleTypeId: '', // 角色类型id
  organizationId: '', // 组织架构id
};

// 添加员工列表搜索表单
export const addStaffSearchForm = {
  account: '', // 账号
  userName: '', // 用户昵称
  phoneNumber: '', // 用户手机号
};
// 添加人员列表配置
export const addStaffTableConfig = {
  columns: [
    {
      title: '账号',
      dataIndex: 'account',
      width: 200,
    },
    {
      title: '姓名',
      dataIndex: 'userName',
      width: 200,
    },
    {
      title: '手机号',
      dataIndex: 'phoneNumber',
      width: 200,
    },
    {
      title: '飞书open_id',
      dataIndex: 'openId',
      width: 200,
    },
  ],
  dataInfo: [],
  selectedRowKeys: [], // Check here to configure the default column
};

export const demandSourceTableData = {
  dataInfo: [],
  columns: [
    {
      title: '需求来源名称',
      dataIndex: 'demandSourceName',
      key: 'demandSourceName',
    },
    {
      title: '创建人',
      dataIndex: 'createName',
      key: 'createName',
    },
    {
      title: '创建时间',
      dataIndex: 'gmtCreate',
      key: 'gmtCreate',
      customRender: (text) => {
        // TODO 待后端处理
        return text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '-';
      },
    },
    {
      title: '编辑人',
      dataIndex: 'modifiedName',
      key: 'modifiedName',
    },
    {
      title: '最后操作时间',
      dataIndex: 'gmtModified',
      key: 'gmtModified',
      customRender(text) {
        // TODO 待后端处理
        return text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '-';
      },
    },
    {
      title: '状态',
      dataIndex: 'enableStatus',
      key: 'enableStatus',
      customRender(text) {
        return text ? '已启用' : '已禁用';
      },
    },
    {
      title: '操作',
      key: 'action',
      width: '200px',
      scopedSlots: { customRender: 'action' },
    },
  ],
  //分页数据
  pagination: {
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0,
    showQuickJumper: true,
    showTotal: (total) => {
      return `共 ${total} 条`;
    },
  },
};

// 需求池分类列表配置
export const demandPoolCategoryTableConfig = {
  dataInfo: [],
  columns: [
    {
      title: '需求分类名称',
      dataIndex: 'classifyName',
      key: 'classifyName',
      width: 250,
    },
    {
      title: '创建人',
      dataIndex: 'createUserName',
      key: 'createUserName',
      width: 100,
    },
    {
      title: '创建时间',
      dataIndex: 'gmtCreate',
      key: 'gmtCreate',
      width: 200,
    },
    {
      title: '编辑人',
      dataIndex: 'modifiedUserName',
      key: 'modifiedUserName',
      width: 100,
    },
    {
      title: '最后编辑时间',
      dataIndex: 'gmtModified',
      key: 'gmtModified',
      width: 200,
    },
    {
      title: '操作',
      key: 'action',
      width: 200,
      scopedSlots: { customRender: 'action' },
    },
  ],
  //分页数据
  pagination: {
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0,
    showQuickJumper: true,
    showTotal: (total) => {
      return `共 ${total} 条`;
    },
  },
  childrenColumnName: 'childList',
};
